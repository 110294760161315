<template>
    <div class="bg-[color:var(--background-color)]
        text-[color:var(--color)]"
        :style="{
            '--background-color': backgroundColor,
            '--color': fontColor
        }">
        <div class="container mx-auto">
            <header class="flex items-center justify-between px-sm sm:px-0">
                <nuxt-link :to="{ name: 'index' }"
                    :aria-label="t('storyblok.home')"
                    class="size-[50px] h-full py-1">
                    <v-icon icon="masku-2024"
                        class="text-[60px] text-white" />
                </nuxt-link>

                <nuxt-link v-if="route.path !== '/checkout/complete'"
                    :to="{ name: 'cart' }"
                    :aria-label="t('back-to-cart')"
                    class="hover:underline">
                    {{ t('back-to-cart') }}
                </nuxt-link>

                <nuxt-link v-else
                    to="/"
                    :aria-label="t('back-to-cart')"
                    class="hover:underline">
                    {{ t('to-home') }}
                </nuxt-link>
            </header>
        </div>
    </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const route = useRoute();

useCustomerLogin();
const headerContent = useStoryblokStory('global/header');

const backgroundColor = computed<string | undefined>(() => (
    headerContent.value?.content?.background_color?.color ?? '#EF1C35'
));

const fontColor = computed<string | undefined>(() => (
    headerContent.value?.content?.font_color ?? '#FFF'
));
</script>
